import React from "react";
import { useSelector } from "react-redux";
const SkillCard = ({ name, imageUrl }) => {
  const isDarkTheme = useSelector((store) => store.theme.isDarkTheme);

  return (
    <div
      className={
        isDarkTheme
          ? "w-32 h-32 sm:w-36 sm:h-36 p-5 bg-[#112240] flex flex-col items-center justify-center text-white gap-2 rounded-xl hover:bg-[#176B87] skill-card"
          : "w-32 h-32 sm:w-36 sm:h-36 p-5 bg-transparent flex flex-col items-center justify-center text-[#696969] gap-2 rounded-xl card-white skill-card"
      }
    >
      <img src={imageUrl} alt={name} className="w-14 h-14" />
      <h1 className="text-xl font-bold">{name}</h1>
    </div>
  );
};

export default SkillCard;
