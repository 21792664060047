import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import useWindowSize from "../utils/useWindowSize";

const Footer = () => {
  const isDarkTheme = useSelector((store) => store.theme.isDarkTheme);
  const size = useWindowSize();

  return (
    <div>
      {size.width < 1024 && (
        <div
          className={
            isDarkTheme
              ? "w-[100%] h-[60px] border-l-2 border-r-2 border-t-2 rounded-tl-2xl rounded-tr-2xl border-[#34739f] flex justify-center items-center fixed left-0 right-0 bottom-0 z-30 bg-gradient-to-r from-[#091529] to-[#000000]"
              : "w-[100%] h-[50px] border-l-2 border-r-2 border-t-2 rounded-tl-2xl rounded-tr-2xl border-[#34739f] flex justify-center items-center fixed left-0 right-0 bottom-0 z-30 bg-white"
          }
        >
          <div className="w-[100%]">
            {isDarkTheme ? (
              <div className="flex justify-evenly">
                <NavLink
                  to="/"
                  style={({ isActive }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isActive ? "#72FFFF" : "#D6D6D6",
                    };
                  }}
                >
                  About
                </NavLink>
                <NavLink
                  to="/resume"
                  style={({ isActive }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isActive ? "#72FFFF" : "#D6D6D6",
                    };
                  }}
                >
                  Resume
                </NavLink>
                <NavLink
                  to="/projects"
                  style={({ isActive }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isActive ? "#72FFFF" : "#D6D6D6",
                    };
                  }}
                >
                  Projects
                </NavLink>
                <NavLink
                  to="/skills"
                  style={({ isActive }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isActive ? "#72FFFF" : "#D6D6D6",
                    };
                  }}
                >
                  Skills
                </NavLink>
                <NavLink
                  to="/contact"
                  style={({ isActive }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isActive ? "#72FFFF" : "#D6D6D6",
                    };
                  }}
                >
                  Contact
                </NavLink>
              </div>
            ) : (
              <div className="flex justify-evenly">
                <NavLink
                  to="/"
                  style={({ isActive }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isActive ? "#FC8019" : "#000000",
                    };
                  }}
                >
                  About
                </NavLink>
                <NavLink
                  to="/resume"
                  style={({ isActive }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isActive ? "#FC8019" : "#000000",
                    };
                  }}
                >
                  Resume
                </NavLink>
                <NavLink
                  to="/projects"
                  style={({ isActive }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isActive ? "#FC8019" : "#000000",
                    };
                  }}
                >
                  Projects
                </NavLink>
                <NavLink
                  to="/skills"
                  style={({ isActive }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isActive ? "#FC8019" : "#000000",
                    };
                  }}
                >
                  Skills
                </NavLink>
                <NavLink
                  to="/contact"
                  style={({ isActive }) => {
                    return {
                      fontWeight: isActive ? "bold" : "",
                      color: isActive ? "#FC8019" : "#000000",
                    };
                  }}
                >
                  Contact
                </NavLink>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Footer;
