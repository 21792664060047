import { configureStore } from "@reduxjs/toolkit";
import themeReducer from "./slices/toggleSlice";
import buttonReducer from "./slices/activeButtonSlice";
const store = configureStore({
  reducer: {
    theme: themeReducer,
    button: buttonReducer,
  },
});
export default store;
