import { createSlice } from "@reduxjs/toolkit";

const activeButtonSlice = createSlice({
  name: "active-button",
  initialState: {
    isActiveAll: true,
    isActiveJs: false,
    isActiveReact: false,
    isActiveNode: false,
  },
  reducers: {
    setActiveAll: (state) => {
      state.isActiveAll = true;
      state.isActiveJs = false;
      state.isActiveReact = false;
      state.isActiveNode = false;
    },
    setActiveJS: (state) => {
      state.isActiveAll = false;
      state.isActiveJs = true;
      state.isActiveReact = false;
      state.isActiveNode = false;
    },
    setActiveReact: (state) => {
      state.isActiveAll = false;
      state.isActiveJs = false;
      state.isActiveReact = true;
      state.isActiveNode = false;
    },
    setActiveNode: (state) => {
      state.isActiveAll = false;
      state.isActiveJs = false;
      state.isActiveReact = false;
      state.isActiveNode = true;
    },
  },
});

export const { setActiveAll, setActiveJS, setActiveReact, setActiveNode } =
  activeButtonSlice.actions;
export default activeButtonSlice.reducer;
