import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import view from "../assets/view.png";
const ProjectCard = ({ url, imageUrl, name, type }) => {
  const isDarkTheme = useSelector((store) => store.theme.isDarkTheme);

  return (
    <div className="flex flex-col items-center">
      <div className="max-w-[320px] max-h-[208px] rounded-3xl image-comtainer z-0 image-container">
        <img className="rounded-3xl" src={imageUrl} alt="project-card" />
        <Link
          to={url}
          target="_blank"
          className=" bg-blue-300 absolute top-[68px] left-[40%] rounded-2xl eye-container z-10 flex justify-center items-center"
        >
          <div>
            <img src={view} alt="eye" className="w-6 h-6" />
          </div>
        </Link>
      </div>
      <div className="mt-3 mb-8 flex flex-col items-center">
        <p
          className={
            isDarkTheme
              ? "text-white text-lg font-medium"
              : "text-black text-lg font-medium"
          }
        >
          {name}
        </p>
        <p className={isDarkTheme ? "text-white" : "text-black"}>{type}</p>
      </div>
    </div>
  );
};

export default ProjectCard;
