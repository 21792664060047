const { createSlice } = require("@reduxjs/toolkit");

const toggleSlice = createSlice({
  name: "theme",
  initialState: {
    isDarkTheme: true,
  },
  reducers: {
    toggleTheme: (state) => {
      state.isDarkTheme = !state.isDarkTheme;
    },
  },
});

export const { toggleTheme } = toggleSlice.actions;
export default toggleSlice.reducer;
