import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, QRCode, theme } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { Helmet } from "react-helmet";
import exp from "../assets/exp.svg";
import { LAPTOP_IMAGE } from "../utils/constant";

const Experience = () => {
  const isDarkTheme = useSelector((store) => store.theme.isDarkTheme);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { useToken } = theme;
  const { token } = useToken();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        open={isModalOpen}
        title="Scan the QR code to download the resume"
        onOk={handleOk}
        onCancel={handleCancel}
        width={400}
        footer={[]}
      >
        <QRCode
          className="mx-auto my-8"
          errorLevel="L"
          value="https://drive.google.com/file/d/1b1rViAYuJNlXmFjJNkEEPb4-RwZ0qi1y/view?usp=sharing"
          icon="https://avatars.githubusercontent.com/u/80765330?v=4"
          color="#000000"
          bgColor={token.colorBgLayout}
        />
      </Modal>

      <div className="flex flex-col gap-10">
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <meta name="author" content="Saurabh Singh" />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <meta property="og:title" content="Saurabh Singh" />
          <meta
            property="og:description"
            content="Welcome to my corner of the web. Explore my projects and learn more about me."
          />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://saurabhsinghayodhya.tech/" />
          <meta
            property="og:image"
            content="https://saurabhsinghayodhya.tech/favicon.jpg"
          />
          <meta property="og:image:alt" content="Saurabh Singh's Portfolio" />
          <meta
            property="og:site_name"
            content="Saurabh Singh | Software Engineer from Ayodhya"
          />
          <meta name="description" content="Saurabh Singh from Ayodhya" />
          <meta
            name="keywords"
            content="Saurabh, Saurabh Singh, Saurabh Singh from Ayodhya, Saurabh Singh Ayodhya, Portfolio, Web Developer, Projects, Skills, Frontend-developer, React, Javascript, Website, Node, Ayodhya, India"
          />

          <meta name="twitter:card" content="Saurabh Singh" />
          <meta name="twitter:site" content="@saurabhsingh_26" />
          <meta name="twitter:creator" content="@saurabhsingh_26" />
          <title>Saurabh Singh | Experiences</title>
          <link rel="canonical" href="https://saurabhsinghayodhya.tech/" />
        </Helmet>
        <div className="flex justify-between items-center">
          <div
            className={
              isDarkTheme
                ? "text-3xl text-white font-semibold title"
                : "text-3xl text-black font-semibold title"
            }
          >
            Resume
          </div>
          <div>
            <Button
              type="primary"
              shape="round"
              icon={<DownloadOutlined />}
              size="large"
              className="bg-[#1677FF]"
              onClick={showModal}
            >
              Download
            </Button>
          </div>
        </div>
        <div className="flex items-start gap-4">
          <div className="bg-gradient-to-br from-[#0096ff] to-[#060047] min-w-[48px] h-12 rounded-lg p-[1px]">
            <div
              className={
                isDarkTheme
                  ? "w-full h-full bg-[#071223] flex justify-center items-center rounded-lg traveller"
                  : "w-full h-full bg-[#FFFFFF] flex justify-center items-center rounded-lg traveller"
              }
            >
              <img
                className="w-5 h-5"
                src={isDarkTheme ? exp : LAPTOP_IMAGE}
                alt="experience"
              />
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <div
              className={
                isDarkTheme
                  ? "flex flex-col text-white"
                  : "flex flex-col text-black"
              }
            >
              <div className="text-2xl font-semibold">Experience</div>
              <div>
                <div className="circle my-8">
                  <p className="text-base font-semibold">Software Engineer</p>
                  <p
                    className={
                      isDarkTheme
                        ? "text-[#D6D6D6] text-lg"
                        : "text-[#000000] text-lg"
                    }
                  >
                    Cognizant Technology Solutions
                  </p>
                  <p
                    className={
                      isDarkTheme ? "text-[#72FFFF]" : "text-[#000000]"
                    }
                  >
                    Nov 2021 — Present
                  </p>
                </div>
                <div
                  className={
                    isDarkTheme
                      ? "flex flex-col gap-4 text-[#D6D6D6]"
                      : "flex flex-col gap-4 text-[#000000]"
                  }
                >
                  <p>
                    ✱ Developed and maintained responsive and user-friendly web
                    applications using HTML, CSS, and JavaScript.
                  </p>
                  <p>
                    ✱ Implemented front-end functionality and intuitive user
                    interfaces using React.js, with a strong focus on
                    performance optimization techniques, including lazy loading
                    of components and data.
                  </p>
                  <p>
                    ✱ Utilized Redux Toolkit for efficient state management,
                    enabling seamless data flow and smooth user experiences
                    throughout the applications.
                  </p>
                  <p>
                    ✱ Leveraged React's lazy loading capabilities to defer the
                    loading of non-critical components, significantly reducing
                    initial load times and enhancing application performance.
                  </p>
                  <p>
                    ✱ Optimize the application's performance through
                    memoization.
                  </p>
                  <p>
                    ✱ Applied debouncing to optimize event handling, preventing
                    excessive API calls, leading to smoother interactions and
                    reduced server load.
                  </p>
                  <p>
                    ✱ Implemented the "Shimmer UI" pattern to provide users with
                    a smooth and responsive experience while waiting for content
                    or data to load asynchronously.
                  </p>
                  <p>
                    ✱ Demonstrate the use of Higher Order Components for code
                    reusability and composability.
                  </p>
                  <p>
                    ✱ Implemented back-end functionalities using Node.js and
                    Express, creating robust and scalable server-side solutions
                    to handle API requests and manage data flow.
                  </p>
                  <p>
                    ✱ Integrated MongoDB as the database layer, designing
                    efficient schemas.
                  </p>
                </div>
              </div>
              <div>
                <div className="circle my-8">
                  <p className="text-base font-semibold">
                    Teaching assistant / Mentor
                  </p>
                  <p
                    className={
                      isDarkTheme
                        ? "text-[#D6D6D6] text-lg"
                        : "text-[#000000] text-lg"
                    }
                  >
                    Coding Ninjas
                  </p>
                  <p
                    className={
                      isDarkTheme ? "text-[#72FFFF]" : "text-[#000000]"
                    }
                  >
                    June 2021 - Oct 2021
                  </p>
                </div>
                <div
                  className={
                    isDarkTheme
                      ? "flex flex-col gap-4 text-[#D6D6D6]"
                      : "flex flex-col gap-4 text-[#000000]"
                  }
                >
                  <p>
                    ✱ Delivered personalized educational, behavioural, and
                    emotional support to individual students to enable positive
                    learning outcomes.
                  </p>
                  <p>
                    ✱ Helped students with their assignments & various projects
                    by Taking doubt sessions and Debugging codes.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-start gap-4">
          <div className="bg-gradient-to-br from-[#0096ff] to-[#060047] min-w-[48px] h-12 rounded-lg p-[1px]">
            <div
              className={
                isDarkTheme
                  ? "w-full h-full bg-[#071223] flex justify-center items-center rounded-lg traveller"
                  : "w-full h-full bg-[#FFFFFF] flex justify-center items-center rounded-lg traveller"
              }
            >
              <img
                className="w-5 h-5"
                src={isDarkTheme ? exp : LAPTOP_IMAGE}
                alt="experience"
              />
            </div>
          </div>
          <div className="flex flex-col gap-5">
            <div className="text-white flex flex-col">
              <div
                className={
                  isDarkTheme
                    ? "text-2xl font-semibold"
                    : "text-2xl font-semibold text-black"
                }
              >
                Education
              </div>
              <div>
                <div className="circle mt-8 mb-2">
                  <p
                    className={
                      isDarkTheme
                        ? "text-base font-semibold"
                        : "text-base font-semibold text-black"
                    }
                  >
                    Bachelors of Technology
                  </p>
                  <p
                    className={
                      isDarkTheme ? "text-[#72FFFF]" : "text-[#000000]"
                    }
                  >
                    2017 — 2021
                  </p>
                  <p
                    className={
                      isDarkTheme
                        ? "text-[#D6D6D6] text-lg"
                        : "text-[#000000] text-lg"
                    }
                  >
                    Ajay Kumar Garg Engineering College
                  </p>
                  <p
                    className={
                      isDarkTheme
                        ? "text-[#D6D6D6] text-sm"
                        : "text-[#000000] text-sm"
                    }
                  >
                    Graduated with first-class honours and achieved an
                    impressive CGPA of 8.4/10.0
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Experience;
