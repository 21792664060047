import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [issending, setIsSending] = useState(false);

  const isDarkTheme = useSelector((store) => store.theme.isDarkTheme);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSending(true);
    const serviceID = process.env.REACT_APP_SERVICE_ID;
    const templateID = process.env.REACT_APP_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_PUBLIC_KEY;

    const templateParams = {
      from_name: name,
      from_email: email,
      to_name: "Saurabh",
      message: message,
    };

    emailjs.send(serviceID, templateID, templateParams, publicKey).then(
      function (response) {
        // console.log("SUCCESS!", response.status, response.text);
        setIsSending(false);
        setName("");
        setEmail("");
        setMessage("");
      },
      function (error) {
        console.log("FAILED...", error);
      }
    );
  };

  return (
    <div className="">
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Saurabh Singh" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta property="og:title" content="Saurabh Singh" />
        <meta
          property="og:description"
          content="Welcome to my corner of the web. Explore my projects and learn more about me."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://saurabhsinghayodhya.tech/" />
        <meta
          property="og:image"
          content="https://saurabhsinghayodhya.tech/favicon.jpg"
        />
        <meta property="og:image:alt" content="Saurabh Singh's Portfolio" />
        <meta
          property="og:site_name"
          content="Saurabh Singh | Software Engineer from Ayodhya"
        />
        <meta name="description" content="Saurabh Singh from Ayodhya" />
        <meta
          name="keywords"
          content="Saurabh, Saurabh Singh, Saurabh Singh from Ayodhya, Saurabh Singh Ayodhya, Portfolio, Web Developer, Projects, Skills, Frontend-developer, React, Javascript, Website, Node, Ayodhya, India"
        />

        <meta name="twitter:card" content="Saurabh Singh" />
        <meta name="twitter:site" content="@saurabhsingh_26" />
        <meta name="twitter:creator" content="@saurabhsingh_26" />
        <title>Saurabh Singh | Contacts</title>
        <link rel="canonical" href="https://saurabhsinghayodhya.tech/" />
      </Helmet>
      <div
        className={
          isDarkTheme
            ? "text-3xl text-white font-semibold title"
            : "text-3xl text-black font-semibold title"
        }
      >
        Contact
      </div>
      <h1
        className={
          isDarkTheme ? "text-white text-lg my-10" : "text-black text-lg my-10"
        }
      >
        Have any suggestions ? Please feel free to connect. Let's stay in touch
        and explore the possibilities together!
      </h1>
      <div className="flex justify-center my-5">
        <div className="w-[100%] sm:w-[90%] md:w-[80%] lg:w-[70%]">
          <h1
            className={
              isDarkTheme
                ? "text-white text-3xl font-semibold text-center my-6"
                : "text-black text-3xl font-semibold text-center my-6"
            }
          >
            Get in Touch
          </h1>
          <form
            onSubmit={handleSubmit}
            className="flex flex-col gap-4 w-[100%]"
          >
            <input
              className="outline-none p-2 border rounded-lg white-form-input"
              type="text"
              value={name}
              placeholder="Name"
              required
              onChange={(e) => setName(e.target.value)}
            />
            <input
              className="outline-none p-2 border rounded-lg white-form-input"
              type="email"
              value={email}
              placeholder="Email"
              required
              onChange={(e) => setEmail(e.target.value)}
            />
            <textarea
              className="outline-none p-2 border rounded-lg white-form-input"
              value={message}
              placeholder="Message"
              required
              rows="6"
              onChange={(e) => setMessage(e.target.value)}
            />
            {/* linear-gradient(to right, #a2ccb6 0%, #ee786e 100%); */}
            <button
              type="submit"
              disabled={issending}
              className={
                message && name && email
                  ? "bg-green-600 p-2 rounded-lg my-4 text-xl text-white font-semibold"
                  : "bg-gradient-to-r from-[#a2ccb6] to-[#ee786e] p-2 rounded-lg my-4 text-xl text-white font-semibold"
              }
            >
              {issending ? "Sending..." : "Send"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
