const projectData = [
  {
    name: "Swiggy Clone",
    url: "https://swiggy-saurabh.vercel.app/",
    type: "React",
    imageUrl:
      "https://images.pexels.com/photos/70497/pexels-photo-70497.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "react-dummy-json",
    url: "https://www.npmjs.com/package/react-dummy-json",
    type: "npm package",
    imageUrl:
      "https://images.pexels.com/photos/11035482/pexels-photo-11035482.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "File Explorer",
    url: "https://saurabh-file-explorer.vercel.app/",
    type: "React",
    imageUrl:
      "https://images.pexels.com/photos/16978372/pexels-photo-16978372.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
  },
  {
    name: "Netflix Clone",
    url: "https://entertainment-saurabh.vercel.app/",
    type: "React",
    imageUrl:
      "https://images.pexels.com/photos/987586/pexels-photo-987586.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "Social Media App",
    url: "https://iconnect-saurabh.vercel.app/",
    type: "React",
    imageUrl:
      "https://images.pexels.com/photos/38544/imac-apple-mockup-app-38544.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "Movie App",
    url: "https://movieapp-saurabh.vercel.app/",
    type: "React",
    imageUrl:
      "https://images.pexels.com/photos/4545951/pexels-photo-4545951.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "Meal App",
    url: "https://saurabhsingh26.github.io/meal-app/",
    type: "JavaScript",
    imageUrl:
      "https://images.pexels.com/photos/1640777/pexels-photo-1640777.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "Todo List",
    url: "https://saurabhsingh26.github.io/Todo-App/",
    type: "JavaScript",
    imageUrl:
      "https://images.pexels.com/photos/3243/pen-calendar-to-do-checklist.jpg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "Issue Tracker App",
    url: "https://github.com/saurabhsingh26/Issue-Tracker-App",
    type: "Node",
    imageUrl:
      "https://images.pexels.com/photos/5699455/pexels-photo-5699455.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "Hospital Api",
    url: "https://github.com/saurabhsingh26/Hospital-API",
    type: "Node",
    imageUrl:
      "https://images.pexels.com/photos/6129049/pexels-photo-6129049.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "E-commerce Api",
    url: "https://github.com/saurabhsingh26/Ecommerce-api",
    type: "Node",
    imageUrl:
      "https://images.pexels.com/photos/6634173/pexels-photo-6634173.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "Employee Review System",
    url: "https://github.com/saurabhsingh26/Employee-Review-System",
    type: "Node",
    imageUrl:
      "https://images.pexels.com/photos/5668858/pexels-photo-5668858.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "Photo Album",
    url: "https://github.com/saurabhsingh26/albums-list",
    type: "React",
    imageUrl:
      "https://images.pexels.com/photos/4554370/pexels-photo-4554370.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "Habit Tracker App",
    url: "https://github.com/saurabhsingh26/habit-tracker-app",
    type: "React",
    imageUrl:
      "https://images.pexels.com/photos/4498483/pexels-photo-4498483.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "Calculator",
    url: "https://calculator-saurabh.vercel.app/",
    type: "React",
    imageUrl:
      "https://images.pexels.com/photos/4386323/pexels-photo-4386323.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "Shopping Cart",
    url: "https://cart-saurabh.vercel.app/",
    type: "React",
    imageUrl:
      "https://images.pexels.com/photos/5632402/pexels-photo-5632402.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "Solar System",
    url: "https://saurabhsingh26.github.io/solar-system/",
    type: "JavaScript",
    imageUrl:
      "https://images.pexels.com/photos/1025469/pexels-photo-1025469.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
  {
    name: "Guess Number",
    url: "https://saurabhsingh26.github.io/guess-my-number/",
    type: "JavaScript",
    imageUrl:
      "https://images.pexels.com/photos/3768136/pexels-photo-3768136.jpeg?auto=compress&cs=tinysrgb&w=600",
  },
];

export default projectData;
