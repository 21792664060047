import React from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector } from "react-redux";
import Header from "./Header";
import Main from "./Main";
import { About, Contact, Experiences, Projects, Skills } from "../pages";
import Footer from "./Footer";
const App = () => {
  const isDarkTheme = useSelector((store) => store.theme.isDarkTheme);

  return (
    <div className={isDarkTheme ? "" : "bg-white h-auto"}>
      <Header />
      <div className="px-4 pb-10 md:px-8 lg:flex lg:gap-10 mb-10 lg:mb-0 mt-14">
        <Main />
        <div className="">
          <div className="border border-[#34739f] rounded-2xl p-6 mt-14 lg:mt-0">
            <Routes>
              <Route path="/" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/resume" element={<Experiences />} />
              <Route path="/projects" element={<Projects />} />
              <Route path="/skills" element={<Skills />} />
            </Routes>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default App;
