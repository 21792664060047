import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProjectCard from "../components/ProjectCard";
import projectData from "../utils/projectData";
import {
  setActiveAll,
  setActiveJS,
  setActiveReact,
  setActiveNode,
} from "../Redux/slices/activeButtonSlice";
import { Helmet } from "react-helmet";

const Projects = () => {
  const [projects] = useState(projectData);
  const [filterProjects, setFilterProjects] = useState(projectData);
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((store) => store.theme.isDarkTheme);
  const check = useSelector((store) => store.button);

  const handleAllClick = () => {
    setFilterProjects(projects);
    dispatch(setActiveAll());
  };
  const handleJsClick = () => {
    const js = projects.filter((data) => data.type === "JavaScript");
    setFilterProjects(js);
    dispatch(setActiveJS());
  };
  const handleReactClick = () => {
    const react = projects.filter((data) => data.type === "React");
    setFilterProjects(react);
    dispatch(setActiveReact());
  };
  const handleNodeClick = () => {
    const node = projects.filter((data) => data.type === "Node");
    setFilterProjects(node);
    dispatch(setActiveNode());
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Saurabh Singh" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta property="og:title" content="Saurabh Singh" />
        <meta
          property="og:description"
          content="Welcome to my corner of the web. Explore my projects and learn more about me."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://saurabhsinghayodhya.tech/" />
        <meta
          property="og:image"
          content="https://saurabhsinghayodhya.tech/favicon.jpg"
        />
        <meta property="og:image:alt" content="Saurabh Singh's Portfolio" />
        <meta
          property="og:site_name"
          content="Saurabh Singh | Software Engineer from Ayodhya"
        />
        <meta name="description" content="Saurabh Singh from Ayodhya" />
        <meta
          name="keywords"
          content="Saurabh, Saurabh Singh, Saurabh Singh from Ayodhya, Saurabh Singh Ayodhya, Portfolio, Web Developer, Projects, Skills, Frontend-developer, React, Javascript, Website, Node, Ayodhya, India"
        />

        <meta name="twitter:card" content="Saurabh Singh" />
        <meta name="twitter:site" content="@saurabhsingh_26" />
        <meta name="twitter:creator" content="@saurabhsingh_26" />
        <title>Saurabh Singh | Projects</title>
        <link rel="canonical" href="https://saurabhsinghayodhya.tech/" />
      </Helmet>
      <div
        className={
          isDarkTheme
            ? "text-3xl text-white font-semibold pb-5 mb-5 title"
            : "text-3xl text-black font-semibold pb-5 mb-5 title"
        }
      >
        Projects
      </div>
      <div
        className={
          isDarkTheme ? "flex gap-5 text-white" : "flex gap-5 text-black"
        }
      >
        <button
          className={
            check.isActiveAll ? "text-[#FC8019]" : "hover:text-gray-500"
          }
          onClick={handleAllClick}
        >
          All
        </button>
        <button
          className={
            check.isActiveJs ? "text-[#FC8019]" : "hover:text-gray-500"
          }
          onClick={handleJsClick}
        >
          JavaScript
        </button>
        <button
          className={
            check.isActiveReact ? "text-[#FC8019]" : "hover:text-gray-500"
          }
          onClick={handleReactClick}
        >
          React
        </button>
        <button
          className={
            check.isActiveNode ? "text-[#FC8019]" : "hover:text-gray-500"
          }
          onClick={handleNodeClick}
        >
          Node
        </button>
      </div>
      <div className="flex flex-wrap justify-evenly mt-10">
        {filterProjects.map((project, index) => (
          <ProjectCard
            key={index}
            url={project.url}
            imageUrl={project.imageUrl}
            name={project.name}
            type={project.type}
          />
        ))}
      </div>
    </>
  );
};

export default Projects;
