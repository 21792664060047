const skillData = [
  {
    name: "HTML",
    imageUrl: "https://www.svgrepo.com/show/349402/html5.svg",
  },
  {
    name: "CSS",
    imageUrl: "https://www.svgrepo.com/show/349330/css3.svg",
  },
  {
    name: "JavaScript",
    imageUrl: "https://www.svgrepo.com/show/349419/javascript.svg",
  },
  {
    name: "React.js",
    imageUrl: "https://www.svgrepo.com/show/452092/react.svg",
  },
  {
    name: "Next.js",
    imageUrl: "https://www.svgrepo.com/show/342062/next-js.svg",
  },
  {
    name: "Router",
    imageUrl: "https://www.svgrepo.com/show/354262/react-router.svg",
  },
  {
    name: "Redux",
    imageUrl: "https://www.svgrepo.com/show/452093/redux.svg",
  },
  {
    name: "Antd",
    imageUrl: "https://www.svgrepo.com/show/353401/ant-design.svg",
  },
  {
    name: "Tailwind",
    imageUrl: "https://www.svgrepo.com/show/374118/tailwind.svg",
  },
  {
    name: "Bootstrap",
    imageUrl:
      "https://getbootstrap.com/docs/5.3/assets/brand/bootstrap-logo-shadow.png",
  },
  {
    name: "Parcel",
    imageUrl: "https://www.svgrepo.com/show/354161/parcel-icon.svg",
  },
  {
    name: "Node.js",
    imageUrl: "https://www.svgrepo.com/show/378837/node.svg",
  },
  {
    name: "Express",
    imageUrl: "https://www.svgrepo.com/show/330398/express.svg",
  },
  {
    name: "GraphQL",
    imageUrl: "https://www.svgrepo.com/show/353834/graphql.svg",
  },
  {
    name: "ejs",
    imageUrl: "https://www.svgrepo.com/show/373574/ejs.svg",
  },
  {
    name: "MongoDB",
    imageUrl: "https://www.svgrepo.com/show/331488/mongodb.svg",
  },
  {
    name: "Postman",
    imageUrl: "https://www.svgrepo.com/show/354202/postman-icon.svg",
  },
  {
    name: "Socket.IO",
    imageUrl: "https://www.svgrepo.com/show/306753/socket-dot-io.svg",
  },
  {
    name: "nodemon",
    imageUrl: "https://www.svgrepo.com/show/354122/nodemon.svg",
  },
  {
    name: "Git",
    imageUrl: "https://www.svgrepo.com/show/452210/git.svg",
  },
  {
    name: "GitHub",
    imageUrl: "https://www.svgrepo.com/show/475654/github-color.svg",
  },
  {
    name: "firebase",
    imageUrl: "https://www.svgrepo.com/show/303670/firebase-1-logo.svg",
  },
  {
    name: "npm",
    imageUrl: "https://www.svgrepo.com/show/354126/npm-icon.svg",
  },
  {
    name: "vs code",
    imageUrl: "https://www.svgrepo.com/show/452129/vs-code.svg",
  },
];

export default skillData;
