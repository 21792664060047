import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ConnectComponent = ({ title, subTitle, url, imageUrl }) => {
  const isDarkTheme = useSelector((store) => store.theme.isDarkTheme);

  return (
    <div className="flex items-center bg-gradient-to-br from-[#0096ff] to-[#060047] rounded-2xl min-w-[80vw] sm:min-w-[70vw] md:min-w-[38vw] lg:min-w-[28vw] p-[1px]">
      <Link
        to={url}
        target="_blank"
        className={
          isDarkTheme
            ? "bg-gradient-to-br from-[#091529] to-[#000000] rounded-2xl flex h-full items-center w-full py-8"
            : "bg-white rounded-2xl w-full flex h-full items-center py-8"
        }
      >
        <div className="mx-6">
          <img className="w-8 h-8" src={imageUrl} alt="email" />
        </div>
        <div>
          <p
            className={
              isDarkTheme
                ? "text-white text-lg font-semibold"
                : "text-[#3D4152] text-lg font-semibold"
            }
          >
            {title}
          </p>
          <p
            className={
              isDarkTheme ? "text-[#D6D6D6] text-sm" : "text-[#000000] text-sm"
            }
          >
            {subTitle}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default ConnectComponent;
