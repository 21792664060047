import React from "react";
import { useSelector } from "react-redux";
import SkillCard from "../components/SkillCard";
import skillData from "../utils/skillData";
import { Helmet } from "react-helmet";

const Skills = () => {
  const isDarkTheme = useSelector((store) => store.theme.isDarkTheme);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Saurabh Singh" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta property="og:title" content="Saurabh Singh" />
        <meta
          property="og:description"
          content="Welcome to my corner of the web. Explore my projects and learn more about me."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://saurabhsinghayodhya.tech/" />
        <meta
          property="og:image"
          content="https://saurabhsinghayodhya.tech/favicon.jpg"
        />
        <meta property="og:image:alt" content="Saurabh Singh's Portfolio" />
        <meta
          property="og:site_name"
          content="Saurabh Singh | Software Engineer from Ayodhya"
        />
        <meta name="description" content="Saurabh Singh from Ayodhya" />
        <meta
          name="keywords"
          content="Saurabh, Saurabh Singh, Saurabh Singh from Ayodhya, Saurabh Singh Ayodhya, Portfolio, Web Developer, Projects, Skills, Frontend-developer, React, Javascript, Website, Node, Ayodhya, India"
        />

        <meta name="twitter:card" content="Saurabh Singh" />
        <meta name="twitter:site" content="@saurabhsingh_26" />
        <meta name="twitter:creator" content="@saurabhsingh_26" />
        <title>Saurabh Singh | Skills</title>
        <link rel="canonical" href="https://saurabhsinghayodhya.tech/" />
      </Helmet>
      <div
        className={
          isDarkTheme
            ? "text-3xl text-white font-semibold pb-5 mb-5 title"
            : "text-3xl text-black font-semibold pb-5 mb-5 title"
        }
      >
        Skills
      </div>
      <div className="flex flex-wrap gap-3 sm:gap-10 justify-center mt-10 mb-5">
        {skillData.map((skill) => (
          <SkillCard
            key={skill.name}
            imageUrl={skill.imageUrl}
            name={skill.name}
          />
        ))}
      </div>
    </>
  );
};

export default Skills;
