import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Collapse } from "react-collapse";
import profile from "../assets/profile.jpg";
import CardComponent from "./CardComponent";

const Main = () => {
  const [showDetails, setShowDetails] = useState(false);
  const isDarkTheme = useSelector((store) => store.theme.isDarkTheme);

  return (
    <div
      className={
        isDarkTheme
          ? "border border-[#34739f]  rounded-2xl px-6 py-10 lg:h-[660px] lg:sticky lg:top-32"
          : "border border-[#34739f] bg-white rounded-2xl px-6 py-10 lg:h-[660px] lg:sticky lg:top-32"
      }
    >
      <div
        className={
          isDarkTheme
            ? "border-b-2 border-l-2 text-[#72FFFF] absolute top-[128px] right-4 md:right-8 px-2 py-1 rounded-bl-2xl border-[#34739f] lg:hidden tracking-tighter text-xs cursor-pointer"
            : "border-b-2 border-l-2 text-[#000000] absolute top-[128px] right-4 md:right-8 px-2 py-1 rounded-bl-2xl border-[#34739f] lg:hidden tracking-tighter text-xs cursor-pointer"
        }
        onClick={() => setShowDetails(!showDetails)}
      >
        {showDetails ? "Hide" : "Show"}
      </div>
      <div className="flex lg:flex-col gap-8">
        <div className="flex justify-center">
          <img
            className="rounded-2xl w-28 h-28 md:w-36 md:h-36 lg:w-48 lg:h-48"
            src={profile}
            alt="profile"
          />
        </div>
        <div
          className={
            isDarkTheme
              ? "text-white flex flex-col items-center"
              : "text-black flex flex-col items-center"
          }
        >
          <h1 className="text-xl md:text-3xl font-semibold md:font-bold name">
            Saurabh Singh
          </h1>
          <p className="py-4 font-bold tracking-wide">Software Engineer</p>
          <a
            class="libutton"
            href="https://www.linkedin.com/comm/mynetwork/discovery-see-all?usecase=PEOPLE_FOLLOWS&followMember=saurabh-raj-singh"
            target="_blank"
            rel="noreferrer"
          >
            Follow on LinkedIn
          </a>
        </div>
      </div>
      <div className="border-t-2 border-[#34739f] mt-10 hidden lg:block">
        <CardComponent
          url="mailto:saurabhsingh6139@gmail.com"
          imageUrl="https://cdn-icons-png.flaticon.com/128/11497/11497503.png"
          title="Email"
          subTitle="saurabhsingh6139@gmail.com"
        />
        <CardComponent
          url="https://github.com/saurabhsingh26"
          imageUrl="https://cdn-icons-png.flaticon.com/128/733/733553.png"
          title="GitHub"
          subTitle="saurabhsingh26"
        />
        <CardComponent
          url="https://www.linkedin.com/in/saurabh-raj-singh/"
          imageUrl="https://cdn-icons-png.flaticon.com/128/2111/2111499.png"
          title="LinkedIn"
          subTitle="saurabh-raj-singh"
        />
      </div>
      <Collapse isOpened={showDetails}>
        <div className="border-t-2 border-[#34739f] mt-10 lg:hidden py-6">
          <CardComponent
            url="mailto:saurabhsingh6139@gmail.com"
            imageUrl="https://cdn-icons-png.flaticon.com/128/11497/11497503.png"
            title="Email"
            subTitle="saurabhsingh6139@gmail.com"
          />
          <CardComponent
            url="https://github.com/saurabhsingh26"
            imageUrl="https://cdn-icons-png.flaticon.com/128/733/733553.png"
            title="GitHub"
            subTitle="saurabhsingh26"
          />
          <CardComponent
            url="https://www.linkedin.com/in/saurabh-raj-singh/"
            imageUrl="https://cdn-icons-png.flaticon.com/128/2111/2111499.png"
            title="LinkedIn"
            subTitle="saurabh-raj-singh"
          />
        </div>
      </Collapse>
    </div>
  );
};

export default Main;
