import React from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleTheme } from "../Redux/slices/toggleSlice";
import light from "../assets/light.svg";
import dark from "../assets/dark.svg";

// import { MOON_IMAGE } from "../utils/constant";

const Header = () => {
  const dispatch = useDispatch();
  const isDarkTheme = useSelector((store) => store.theme.isDarkTheme);
  return (
    <div
      className={
        isDarkTheme
          ? "flex justify-center lg:justify-between px-8 py-4 items-center sticky top-0 bg-gradient-to-r from-[#091529] to-[#000000] shadow-lg z-10"
          : "flex justify-center lg:justify-between px-8 py-4 items-center sticky top-0 bg-white z-10 shadow-2xl"
      }
    >
      <div
        className={
          isDarkTheme
            ? "flex justify-center items-center w-10 h-10 rounded-[50%] button-black"
            : "flex justify-center items-center w-10 h-10 rounded-[50%] button-white"
        }
      >
        <button onClick={() => dispatch(toggleTheme())}>
          <img
            src={isDarkTheme ? light : dark}
            alt="theme"
            title={isDarkTheme ? "Light Theme" : "Dark Theme"}
          />
        </button>
      </div>
      <div className="hidden lg:block">
        {isDarkTheme ? (
          <div className="flex justify-end gap-5">
            <NavLink
              to="/"
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#72FFFF" : "#D6D6D6",
                };
              }}
            >
              About
            </NavLink>
            <NavLink
              to="/resume"
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#72FFFF" : "#D6D6D6",
                };
              }}
            >
              Resume
            </NavLink>
            <NavLink
              to="/projects"
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#72FFFF" : "#D6D6D6",
                };
              }}
            >
              Projects
            </NavLink>
            <NavLink
              to="/skills"
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#72FFFF" : "#D6D6D6",
                };
              }}
            >
              Skills
            </NavLink>
            <NavLink
              to="/contact"
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#72FFFF" : "#D6D6D6",
                };
              }}
            >
              Contact
            </NavLink>
          </div>
        ) : (
          <div className="flex justify-end gap-5">
            <NavLink
              to="/"
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#FC8019" : "#000000",
                };
              }}
            >
              About
            </NavLink>
            <NavLink
              to="/resume"
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#FC8019" : "#000000",
                };
              }}
            >
              Resume
            </NavLink>
            <NavLink
              to="/projects"
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#FC8019" : "#000000",
                };
              }}
            >
              Projects
            </NavLink>
            <NavLink
              to="/skills"
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#FC8019" : "#000000",
                };
              }}
            >
              Skills
            </NavLink>
            <NavLink
              to="/contact"
              style={({ isActive }) => {
                return {
                  fontWeight: isActive ? "bold" : "",
                  color: isActive ? "#FC8019" : "#000000",
                };
              }}
            >
              Contact
            </NavLink>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
