import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const CardComponent = ({ title, subTitle, url, imageUrl }) => {
  const isDarkTheme = useSelector((store) => store.theme.isDarkTheme);

  return (
    <div className="">
      <Link to={url} target="_blank" className="flex gap-3 my-6">
        <div className="bg-gradient-to-br from-[#0096ff] to-[#060047] w-12 h-12 rounded-lg p-[1px]">
          <div
            className={
              isDarkTheme
                ? "w-full h-full bg-[#071223] flex justify-center items-center rounded-lg"
                : "w-full h-full bg-[#FFFFFF] flex justify-center items-center rounded-lg"
            }
          >
            <img className="w-5 h-5 " src={imageUrl} alt={title} />
          </div>
        </div>
        <div>
          <p className={isDarkTheme ? "text-[#D6D6D6B2]" : "text-[#3D4152]"}>
            {title}
          </p>
          <p className={isDarkTheme ? "text-[#ffffff]" : "text-[#000000]"}>
            {subTitle}
          </p>
        </div>
      </Link>
    </div>
  );
};

export default CardComponent;
