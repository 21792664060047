import React from "react";
import { useSelector } from "react-redux";
import ConnectComponent from "../components/ConnectComponent";
import { Helmet } from "react-helmet";

const About = () => {
  const isDarkTheme = useSelector((store) => store.theme.isDarkTheme);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Saurabh Singh" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta property="og:title" content="Saurabh Singh" />
        <meta
          property="og:description"
          content="Welcome to my corner of the web. Explore my projects and learn more about me."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://saurabhsinghayodhya.tech/" />
        <meta
          property="og:image"
          content="https://saurabhsinghayodhya.tech/favicon.jpg"
        />
        <meta property="og:image:alt" content="Saurabh Singh's Portfolio" />
        <meta
          property="og:site_name"
          content="Saurabh Singh | Software Engineer from Ayodhya"
        />
        <meta name="description" content="Saurabh Singh from Ayodhya" />
        <meta
          name="keywords"
          content="Saurabh, Saurabh Singh, Saurabh Singh from Ayodhya, Saurabh Singh Ayodhya, Portfolio, Web Developer, Projects, Skills, Frontend-developer, React, Javascript, Website, Node, Ayodhya, India"
        />

        <meta name="twitter:card" content="Saurabh Singh" />
        <meta name="twitter:site" content="@saurabhsingh_26" />
        <meta name="twitter:creator" content="@saurabhsingh_26" />
        <title>Saurabh Singh | Software Engineer from Ayodhya</title>
        <link rel="canonical" href="https://saurabhsinghayodhya.tech/" />
      </Helmet>
      <div
        className={
          isDarkTheme
            ? "text-3xl text-white font-semibold pb-5 mb-5 title"
            : "text-3xl text-black font-semibold pb-5 mb-5 title"
        }
      >
        About Me
      </div>
      <div className={isDarkTheme ? "text-[#D6D6D6]" : "text-[#686B78]"}>
        <p className="text-justify">
          Welcome to my corner of the web. I'm Saurabh Singh, a Software
          Engineer hailing from the historic city of Ayodhya.
        </p>
        <br />
        <p className="text-justify">
          My professional journey is characterized by a relentless pursuit of
          new challenges, where I consistently push boundaries and seek
          creative, out-of-the-box solutions to complex problems. Beyond my
          passion for innovation, I am deeply committed to nurturing the minds
          of the next generation. Engaging with young minds allows me to explore
          their unique perspectives on real-world issues.
        </p>
        <br />
        <p className="text-justify">
          Thank you for visiting my website, and I invite you to explore further
          to learn more about my journey and the exciting projects I've been a
          part of.
        </p>
      </div>
      <div>
        <h1
          className={
            isDarkTheme
              ? "text-white text-3xl font-semibold my-10"
              : "text-black text-3xl font-semibold my-10"
          }
        >
          Let's Connect
        </h1>
        <div className="flex flex-wrap justify-center gap-6">
          <ConnectComponent
            url="https://www.linkedin.com/in/saurabh-raj-singh/"
            title="LinkedIn"
            subTitle="Let's connect on LinkedIn"
            imageUrl="https://cdn-icons-png.flaticon.com/128/2111/2111499.png"
          />
          <ConnectComponent
            url="https://github.com/saurabhsingh26"
            title="GitHub"
            subTitle="Check my codes on GitHub"
            imageUrl="https://cdn-icons-png.flaticon.com/128/270/270798.png"
          />
          <ConnectComponent
            url="https://saurabhsingh26.github.io/mywebsite/"
            title="Website"
            subTitle="Know more about me"
            imageUrl="https://cdn-icons-png.flaticon.com/128/11622/11622033.png"
          />
          <ConnectComponent
            url="https://discordapp.com/users/saurabhsingh_26"
            title="Discord"
            subTitle="Let's talk on Discord"
            imageUrl="https://cdn-icons-png.flaticon.com/128/4945/4945973.png"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
